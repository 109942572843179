import React from 'react'
import Layout from '../layout/Layout'
import { Row, Col, Card } from 'react-bootstrap';

const Home = () => {
    return (
        <Layout sidebar={true}>
            <Row>
                <Col md={12}>
                    <Card>
                        <Card.Body>
                            <h3 className='mb-0 text-center fw-700'>Welcome to Art Work</h3>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

export default Home