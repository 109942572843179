import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch'
import { toast } from "react-toastify";
import { API } from "../../App";
import Cookie from 'js-cookie'

const AppListiOSEdit = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const [validate, setValidate] = useState(false)
    const [data, setData] = useState({
        name: "",
        package_name: "",
        minimum_version: "",
        start_io_ads_enable: ""

    })

    const lodadata = async () => {
        const token = Cookie.get('atjwt')
        const res = await API.post(`/viewiosdata/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
        console.log(res);
        setData({
            name: res.data.data.name,
            package_name: res.data.data.package_name,
            minimum_version: res.data.data.minimum_version,
            start_io_ads_enable: res.data.data.start_io_ads_enable
        })
    }

    const handlerchange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        console.log(data);
    }


    const dataSubmit = async () => {
        const form = new FormData();
        if (data.name === "" || !data.name) {
            setValidate(true)
        }
        else if (data.package_name === "" || !data.package_name) {
            setValidate(true)
        }
        else if (data.minimum_version === "" || !data.minimum_version) {
            setValidate(true)
        } else {
            form.append("name", data.name);
            form.append("package_name", data.package_name);
            form.append("minimum_version", data.minimum_version);
            form.append("start_io_ads_enable", data.start_io_ads_enable)
            const token = Cookie.get('atjwt')
            const res = await API.post(`/editiOSList/${id}`, form, { headers: { "Authorization": `Bearer ${token}` } })
            console.log(res);
            if (res.data.status === true) {
                toast.success("Update data sucessfully", { position: "bottom-right", autoClose: 500 })
                navigate("/AppListiOS")
            }
            else {
                toast.error(res.data.data.response_message, { position: "bottom-right", autoClose: 500 })

            }
            console.log(res);
        }

    }
    const statuschange = (e) => {
        const result = (e === true) ? 1 : 0
        setData({
            ...data, ['start_io_ads_enable']: result
        })
    }
    useEffect(() => {
        lodadata()
    }, [])


    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Edit App List iOS</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/home"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/AppListiOS">App List iOS</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit App List iOS</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label htmlFor="appname">App Name</Form.Label>
                                            <Form.Control type="text" className="my-2" maxLength={150} name="name" value={data.name} onChange={handlerchange} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter AppName
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="appid">App Id</Form.Label>
                                            <Form.Control type="text" className="my-2" maxLength={150} name="package_name" value={data.package_name} onChange={handlerchange} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter App Id
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="minimumversion">Minimum Version</Form.Label>
                                            <Form.Control type="number" pattern="0-9" className="my-2" name="minimum_version" value={data.minimum_version} onChange={handlerchange} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Minimum Version
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="status" className="d-block mb-2">Status</Form.Label>
                                            <Switch
                                                onChange={statuschange}
                                                checked={data.start_io_ads_enable === 1 ? 1 : 0}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className="react-switch-off">OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className="react-switch-on">ON</div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={dataSubmit}>Save</Button>
                                    <Link to='/AppListiOS'>
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default AppListiOSEdit