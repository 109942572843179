import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'rsuite/dist/rsuite.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.css';


import Home from './pages/Home';
import Login from './pages/Login';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './utilities.css';
import AppListAndroid from './pages/AppListAndroid/AppListAndroid';
import AppListAndroidAdd from './pages/AppListAndroid/AppListAndroidAdd';
import AppListAndroidEdit from './pages/AppListAndroid/AppListAndroidEdit';
import AppListAndroidView from './pages/AppListAndroid/AppListAndroidView';

import AppListiOS from './pages/AppListiOS/AppListiOS';
import AppListiOSAdd from './pages/AppListiOS/AppListiOSAdd';
import AppListiOSEdit from './pages/AppListiOS/AppListiOSEdit';
import AppListiOSView from './pages/AppListiOS/AppListiOSView';
import Protected from './Component/Protect';
import PrivetRoutes from './Component/PrivetRoutes';
import axios from 'axios';

export const API = axios.create({ baseURL: process.env.REACT_APP_URL })




function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<Protected />}>
                        <Route path="/" element={<Login />} />
                    </Route>
                    <Route element={<PrivetRoutes />}>
                        <Route path="/home" element={<Home />} />
                        <Route path="/AppListAndroid" element={<AppListAndroid />} />
                        <Route path="/AppListAndroid/Add" element={<AppListAndroidAdd />} />
                        <Route path="/AppListAndroid/Edit/:id" element={<AppListAndroidEdit />} />
                        <Route path="/AppListAndroid/View/:id" element={<AppListAndroidView />} />

                        <Route path="/AppListiOS" element={<AppListiOS />} />
                        <Route path="/AppListiOS/Add" element={<AppListiOSAdd />} />
                        <Route path="/AppListiOS/Edit/:id" element={<AppListiOSEdit />} />
                        <Route path="/AppListiOS/View/:id" element={<AppListiOSView />} />
                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer></ToastContainer>
        </>
    )
}

export default App;
