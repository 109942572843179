import React, { useState } from "react";
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from '../../layout/Layout';
import Switch from 'react-switch'
import { API } from "../../App";
import { toast } from "react-toastify";
import Cookie from 'js-cookie'

const AppListiOSAdd = () => {


    const [status, setstatus] = useState(1)
    const [validate, setValidate] = useState(false)
    const [data, setData] = useState({
        appname: "",
        packagename: "",
        minimumversion: ""
    })
    const navigate = useNavigate()

    const handlerchange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
        console.log(data);
    }

    const dataSubmit = async () => {
        const form = new FormData();
        if (data.appname === "" || !data.appname) {
            setValidate(true)
        }
        else if (data.packagename === "" || !data.packagename) {
            setValidate(true)
        }
        else if (data.minimumversion === "" || !data.minimumversion) {
            setValidate(true)
        } else {
            form.append("name", data.appname);
            form.append("package_name", data.packagename);
            form.append("minimum_version", data.minimumversion);
            form.append("start_io_ads_enable", status === 1 ? 1 : 0)
            const token = Cookie.get('atjwt')
            const res = await API.post("/AddiOSList", form, { headers: { "Authorization": `Bearer ${token}` } })
            if (res.data.status === true) {
                toast.success("Add data sucessfully", { position: "bottom-right", autoClose: 500 })
                navigate("/AppListiOS")
            }
            else {
                toast.error(res.data.data.response_message, { position: "bottom-right", autoClose: 500 })
            }
            console.log(res);
        }

    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Create App List iOS</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/home"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/AppListiOS">App List iOS</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Create App List iOS</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label htmlFor="appname">App Name</Form.Label>
                                            <Form.Control type="text" className="my-2" maxLength={150} name="appname" onChange={handlerchange} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter AppName
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="appid">App Id</Form.Label>
                                            <Form.Control type="text" className="my-2" maxLength={150} name="packagename" onChange={handlerchange} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter App Id
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="minimumversion">Minimum Version</Form.Label>
                                            <Form.Control type="number" className="my-2" pattern="0-9" name="minimumversion" onChange={handlerchange} required />
                                            <Form.Control.Feedback type="invalid">
                                                Enter Minimum Version
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label htmlFor="status" className="d-block mb-2">Status</Form.Label>
                                            <Switch
                                                onChange={(checked) => { if (checked === false) { setstatus(false); console.log(checked, "s-on") } else { setstatus(true); console.log(checked, "s-off") } }}
                                                checked={status}
                                                offColor="#C8C8C8"
                                                onColor="#0093ed"
                                                height={30}
                                                width={70}
                                                className="react-switch"
                                                uncheckedIcon={
                                                    <div className="react-switch-off">OFF</div>
                                                }
                                                checkedIcon={
                                                    <div className="react-switch-on">ON</div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={dataSubmit}>Save</Button>
                                    <Link to='/AppListiOS'>
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default AppListiOSAdd