import React, { useState } from 'react'
import { Button, Container, Card, Form } from 'react-bootstrap';
import LogoMini from '../Component/Logo-mini';
import Cookie from 'js-cookie'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { API } from '../App.js';
import base64 from "base-64"


let userdata
const Login = () => {

    let navigate = useNavigate();
    const [eye, seteye] = useState(true);
    const [password, setpassword] = useState("password");
    const [type, settype] = useState(false);
    const [inpData, setInpData] = useState({
        email: (localStorage.getItem('ejwt')) ? base64.decode(localStorage.getItem('ejwt')) : "",
        password: (localStorage.getItem('pjwt')) ? base64.decode(localStorage.getItem('pjwt')) : "",
        remember: (localStorage.getItem('ejwt') && localStorage.getItem('pjwt')) ? true : false
    })


    const getValue = (e) => {
        setInpData({ ...inpData, [e.target.name]: e.target.value })
    }

    const Remember = (e) => {
        setInpData({ email: inpData.email, password: inpData.password, remember: e.target.checked })
    }

    const Eye = () => {
        if (password == "password") {
            setpassword("text");
            seteye(false);
            settype(true);
        }
        else {
            setpassword("password");
            seteye(true);
            settype(false);
        }
    }

    const Logindata = async (event) => {
        event.preventDefault()
        const { email, password } = document.forms[0]
        const Form = new FormData()
        Form.append('email', email.value)
        Form.append('password', password.value)
        const res = await API.post("/login", Form)
        console.log(res, "login");

        if (res.data.response_code === 200) {

            const emailEnc = base64.encode(inpData.email)
            const passEnc = base64.encode(inpData.password)
            if (inpData.remember === true) {
                localStorage.setItem('ejwt', emailEnc)
                localStorage.setItem('pjwt', passEnc)
            }
            else {
                localStorage.removeItem('ejwt')
                localStorage.removeItem('pjwt')
            }
            toast.success("Login Sucessfully", { position: "bottom-right", autoClose: 500 })
            Cookie.set('atjwt', res.data.data, { expires: 1 })
            navigate("/AppListAndroid")
        }
        else if (res.data.response_code === 401) {
            toast.error(res.data.response_message, { position: "bottom-right", autoClose: 500 })
            navigate("/")
        }
        else if (res.data.response_code === 400) {
            toast.error(res.data.response_message, { position: "bottom-right", autoClose: 500 })
            navigate("/")
        }
    }


    return (
        <>
            <Container>
                <div className="auth">
                    <div className="auth-box">
                        <Card>
                            <Card.Header className="pb-0">
                                <div className='auth-logo'>
                                    <LogoMini />
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Form method='post'>
                                    <h1 className='auth-title'>Sign in</h1>
                                    <h2 className='auth-subtitle'>Art Work</h2>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" className="my-2" placeholder="Enter Your Email" name='email' value={inpData.email} onChange={(e) => { getValue(e) }} />
                                    </Form.Group>
                                    <Form.Group className="mb-4 input-prefix">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type={password} className="my-2" placeholder="Enter Password" name='password' value={inpData.password} onChange={(e) => { getValue(e) }} />
                                        <i onClick={Eye} className={`bx ${eye ? "bx-hide" : "bx-show"}`}></i>
                                    </Form.Group>
                                    <Form.Group className="mb-4" controlId="formBasicCheckbox">
                                        <Form.Check type="checkbox" label="Remember Me" checked={inpData.remember} onClick={(e) => { Remember(e) }} />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className="w-100 " onClick={Logindata}>Sign In</Button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Login