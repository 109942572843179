import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import DarkMode from "../Component/DarkMode";
import { Button, Dropdown } from 'react-bootstrap';
import LogoMini from '../Component/Logo-mini';
import { API } from "../App"
import { toast } from 'react-toastify';
import Cookie from 'js-cookie'

const Header = ({ openSidebar }) => {
    const Navigate = useNavigate()

    const Cookieclear = async () => {
        console.log("in");
        const token = Cookie.get('atjwt')
        const res = await API.post(`/logout`, {}, { headers: { "Authorization": `Bearer ${token}` } })
        console.log(res, "logout");
        Cookie.remove('atjwt')
        toast.success("Logout SucessFully", { position: "bottom-right", autoClose: 500 })
        Navigate("/")

    }
    return (
        <>
            <header className="sidebar-header">
                <div className="header-left-menu">
                    <Link to="/" className='d-xl-none'>
                        <LogoMini />
                    </Link>
                </div>
                <div className="header-right-menu">
                    <DarkMode />
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-autoclose-true">
                            <div className="user-menu">
                                <div className="user-img">
                                    <LogoMini />
                                </div>
                                <div className="user-name ms-2">
                                    <h6>Art Work</h6>
                                    <p>Admin</p>
                                </div>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                            <Dropdown.Item>
                                <a href="" onClick={Cookieclear}>
                                    <div className='Profile  dropDownBTN'>
                                        Logout
                                    </div>
                                </a>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Button className="burger-btn d-xl-none" onClick={openSidebar}>
                        <i className='bx bx-menu fs-3'></i>
                    </Button>
                </div>
            </header>
        </>
    )
}

export default Header