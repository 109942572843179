import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from "react-bootstrap";
import Layout from '../../layout/Layout';
import { SelectPicker } from 'rsuite';
import { API } from '../../App';
import Cookie from 'js-cookie'

const AppListAndroidView = () => {
    const [Edit, SetEdit] = useState([])
    const [LoadData, setLoadData] = useState([])
    const [Data, SetData] = useState({
        name: "",
        package_name: "",
        minimum_version: "",
        start_io_ads_enable: ""

    })
    const navigate = useNavigate()
    const { id } = useParams()
    const GetData = async (id) => {
        const token = Cookie.get('atjwt')
        const res = await API.post(`/listAdvData/`, {}, { headers: { "Authorization": `Bearer ${token}` } })
        setLoadData(res.data.data)
        const viewdata = await API.post(`/viewAdvData/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
        SetEdit(viewdata.data.data._id)
        SetData({
            name: viewdata.data.data.name,
            package_name: viewdata.data.data.package_name,
            minimum_version: viewdata.data.data.minimum_version,
            start_io_ads_enable: viewdata.data.data.start_io_ads_enable

        })

    }

    const getCategory = (e) => {
        SetEdit(e)
        GetData(e)
        navigate(`/AppListAndroid/View/${e}`)
    }

    useEffect(() => {
        GetData(id)
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3><Link to="/AppListAndroid" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>View App List Android</h3>
                <div className="page-heading-right">
                    <SelectPicker
                        data={LoadData}
                        defaultValue={id}
                        valueKey="_id"
                        labelKey="name"
                        placement="bottomEnd"
                        className="my-1 ms-3 wv-200"
                        placeholder="Select Name"
                        cleanable={false}
                        onChange={(e) => getCategory(e)} />
                    <Link to={`/AppListAndroid/Edit/${Edit}`}>
                        <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
                    </Link>
                </div>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Name</p><span>{Data.name}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Package Name</p><span>{Data.package_name}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Minimum Version</p><span>{Data.minimum_version}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p><span>{Data.start_io_ads_enable == 1 ? "true" : "false"}</span>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default AppListAndroidView