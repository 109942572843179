import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { API } from "../App";
import Cookies from "js-cookie";

const Protected = () => {
    const navigate = useNavigate();
    let Auth = {
        token: Cookies.get("atjwt")
    }
    const Authotication = async () => {
        const result = await API.post("/homeApi", {}, { headers: { Authorization: `Bearer ${Auth.token}` } });
        if (result.data.status === false) {
            navigate("/");
        }
    }
    useEffect(() => {
        Authotication()
    }, [])

    return (
        Auth.token ? <Navigate to={"/AppListAndroid"} /> : <Outlet />
    )
}

export default Protected