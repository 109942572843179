import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import Layout from '../../layout/Layout';
import Pagination from "rc-pagination";
import Switch from 'react-switch'
import { API } from "../../App";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Cookie from 'js-cookie'
import { SelectPicker } from "rsuite";





const datas = ['10', '25', '50', "100"].map(
    item => ({ label: item, value: item })
);
const AppListiOS = () => {

    const [data, setData] = useState([])
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [dataLength, setdataLength] = useState(1)
    const [iconcoror, Seticoncoror] = useState("name_up")
    const [search, setSearch] = useState({
        name: "",
        package_name: "",
    })
    const [loading, Setloading] = useState(true)


    const getData = async () => {
        Setloading(true)
        const token = Cookie.get('atjwt')
        const res = await API.post("/iOSListData", {}, { headers: { "Authorization": `Bearer ${token}` } })
        setData(res.data.data);
        setdataLength(1)
        Setloading(false)
    }


    const getdata = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }


    const deleteUser = async (_id) => {

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-3'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const dele = await API.post(`/deleteiOSList/${_id}`)
                getData()
            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {

            }
        })

    }

    const change = async (id, e, name, ind) => {

        var Status
        Status = (e === true) ? 1 : 0
        toast.success("Update Data sucessfully", { position: "bottom-right", autoClose: 500 })
        if (name === "start_io_ads_enable") {

            const data1 = data
            data1[ind].start_io_ads_enable = Status
            setData(data1)
        }
        const Form = new FormData()
        Form.append('id', id)
        Form.append('name', name)
        Form.append('Status', Status)

        const res = await API.post("/updateiOSStatus", Form)
        console.log(res, "ress")
        getData()


    }

    const sorting = (col, order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
            const sorted = [...data].sort((a, b) =>
                a[col] > b[col] ? 1 : -1
            );
            if (iconcoror !== e.target.id) {
                setData(sorted)
            }
        }
        if (order === "DSC") {
            const sorted = [...data].sort((a, b) =>
                a[col] < b[col] ? 1 : -1
            );
            if (iconcoror !== e.target.id) {
                setData(sorted)
            }
        }
    }

    const SerachData = async (e) => {
        console.log(e.target.value);
        let srcData = search
        srcData[e.target.name] = e.target.value
        setSearch(srcData)
        // setSearch({ ...search, [e.target.name]: e.target.value })
        const Form = new FormData()
        Form.append('name', srcData.name)
        Form.append('package_name', srcData.package_name)

        const res = await API.post(`/serachingiOS`, Form)
        console.log(res);
        setData(res.data.data)
        if (res.data.data.length === 0) {
            setdataLength(0)
        } else {
            setdataLength(1)
        }
    }
    useEffect(() => {
        getData();
    }, [])


    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">App List iOS</h3>
                <div className="page-heading-right">
                    <Form.Control type="text" name="name" id="" placeholder="Search Name" className="wv-200 my-1 ms-3" onChange={SerachData} />
                    <Form.Control type="text" name="package_name" id="" placeholder="Search App Id" className="wv-200 my-1 ms-3" onChange={SerachData} />
                    <SelectPicker
                        cleanable={false}
                        data={datas}
                        searchable={false}
                        value={size}
                        onChange={(e) => { setSize(e); setCurrent(1) }}
                        className="wv-100 my-1 ms-3"
                        placeholder="Page"
                    />
                    <Link to="/AppListiOS/Add" className="my-1 ms-3">
                        <Button variant="primary" value="create">Add New</Button>
                    </Link>
                </div>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                {(loading === true) ? <div className="loader table-loader" ></div> : <></>}

                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th width="5%">
                                                <div className='table-sort-filter justify-content-center'>No</div>
                                            </th>
                                            <th width="45%">
                                                <div className='table-sort-filter'>Name
                                                    <span className="table-sort">
                                                        <div className={`sort-down ${iconcoror === "name_down" ? "active" : ""}`} id="name_down" onClick={(e) => { sorting('name', "DSC", e) }}></div>
                                                        <div className={`sort-up ${iconcoror === "name_up" ? "active" : ""}`} id="name_up" onClick={(e) => { sorting('name', "ASC", e) }}></div>
                                                    </span>
                                                </div>
                                            </th>
                                            <th width="20%">
                                                <div className='table-sort-filter'>App Id
                                                    <span className="table-sort">
                                                        <div className={`sort-down ${iconcoror === "package_name_down" ? "active" : ""}`} id="package_name_down" onClick={(e) => { sorting('package_name', "DSC", e) }}></div>
                                                        <div className={`sort-up ${iconcoror === "package_name_up" ? "active" : ""}`} id="package_name_up" onClick={(e) => { sorting('package_name', "ASC", e) }}></div>
                                                    </span>
                                                </div>
                                            </th>
                                            <th width="10%" className='text-center'>Minimum Version</th>
                                            <th width="10%" className="text-center">Status</th>
                                            <th width="10%" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getdata(current, size).map((value, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='text-center'>{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                                                        <td>{value.name}</td>
                                                        <td>{value.package_name}</td>
                                                        <td className='text-center'>{value.minimum_version}</td>
                                                        <td className='text-center'>
                                                            <Switch
                                                                onChange={(e) => { change(value._id, e, "start_io_ads_enable", index) }}
                                                                checked={value.start_io_ads_enable === 1 ? 1 : 0}
                                                                offColor="#C8C8C8"
                                                                onColor="#0093ed"
                                                                height={30}
                                                                width={70}
                                                                className="react-switch"
                                                                uncheckedIcon={
                                                                    <div className="react-switch-off">OFF</div>
                                                                }
                                                                checkedIcon={
                                                                    <div className="react-switch-on">ON</div>
                                                                }
                                                            />
                                                        </td>
                                                        <td className='text-center'>
                                                            <Link to={`/AppListiOS/View/${value?._id}`} >
                                                                <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                            </Link>
                                                            <Button variant="outline-danger" size="sm" className="btn-icon"><i className='bx bx-trash-alt' onClick={() => deleteUser(value._id)}></i></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                    {
                                        data.length === 0 ? <tr>
                                            <td colSpan="100%" className="p-0">
                                                <div className='no-found'>
                                                    <img src="../../not-found/themes.svg" />
                                                    <p>No Found iOS List</p>
                                                </div>
                                            </td>
                                        </tr> : ""
                                    }
                                </Table>
                                {(data.length > size) ?
                                    <div className="pagination-custom">
                                        <Pagination
                                            className="pagination-data"
                                            onChange={PaginationChange}
                                            total={data.length}
                                            current={current}
                                            pageSize={size}
                                            showSizeChanger={false}
                                            itemRender={PrevNextArrow}
                                            onShowSizeChange={PerPageChange}
                                            showTitle={false}
                                        />
                                    </div> : ""}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default AppListiOS